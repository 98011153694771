<template>
  <div class="careers smaller-inner">
    <div class="careers">
      <div class="careers-image desktop_img">
        <FocalImage :src="banner_image_desktop" />
      </div>
      <div class="careers-image mobile_img">
        <FocalImage :src="banner_image_mobile" />
      </div>
      <div class="careers-menu">
        <ul class="careers-list">
          <li>
            <div class="menu-item">
              <a @click="moveUp('who')">WHO WE ARE</a>
            </div>
          </li>
          <li>
            <div class="menu-item">
              <a @click="moveUp('culture')">OUR CULTURE</a>
            </div>
          </li>
          <li>
            <div class="menu-item">
              <a @click="moveUp('offer')">WHAT WE OFFER</a>
            </div>
          </li>
          <li>
            <div class="menu-item">
              <a @click="moveUp('role')">OUR ROLES</a>
            </div>
          </li>
        </ul>
      </div>
      <div class="careers-content">
        <div class="careers-heading">
          <p class="smaller-text">We believe the sun makes everything better. That’s why we make sure everyone at
            Sunglass Hut is welcomed with the warmth of a smile and a helpful hand.</p>
          <p class="smaller-text">Our store teams across the globe are at the very core of our mission – creating that
            warm, Sunglass Hut feeling for our guests every day. Sound like you? Read on! </p>
        </div>
        <div class="section" id="who">
          <div class="image">
            <FocalImage :src="building1_image" />
          </div>
          <div class="text who-padding">
            <h1 class="title">Who are we</h1>
            <p class="smaller-text">Born in 1971, we started as a small family business running a kiosk in a Miami mall.
              In just over 50 years, we’ve grown that business into 3,000+ stores worldwide as part of EssilorLuxottica,
              global leader in eyewear and home to eyewear’s most famous brands.</p>
            <p class="smaller-text">Even with our growth, we strive to bring that same family feel and welcoming spirit
              as the very first Sunglass Hut to our stores today. And that’s where you come in.</p>
          </div>
        </div>
        <div class="section" id="culture">
          <div class="image mobile_img">
            <FocalImage :src="building2_image" />
          </div>
          <div class="text">
            <h1 class="title left">Our culture</h1>
            <div class="point">
              <div class="icon-culture">
                <FocalImage :src="icon_inclusion" />
              </div>
              <div class="point-text">
                <h1>Inclusion</h1>
                <p>Individuality thrives here and everyone is welcome</p>
              </div>
            </div>
            <div class="point">
              <div class="icon-culture mobile_img">
                <FocalImage :src="icon_fun" />
              </div>
              <div class="point-text">
                <h1>Fun</h1>
                <p>We empower others through uplifting energy & authenticity</p>
              </div>
              <div class="icon-culture desktop_img">
                <FocalImage :src="icon_fun" />
              </div>
            </div>
            <div class="point">
              <div class="icon-culture">
                <FocalImage :src="icon_drive" />
              </div>
              <div class="point-text">
                <h1>Drive</h1>
                <p>We set ambitious goals and we go further together</p>
              </div>
            </div>
            <div class="point">
              <div class="icon-culture mobile_img">
                <FocalImage :src="icon_relationships" />
              </div>
              <div class="point-text">
                <h1>Relationships</h1>
                <p>We are a global community connected by a shared passion for self-expression</p>
              </div>
              <div class="icon-culture desktop_img">
                <FocalImage :src="icon_relationships" />
              </div>
            </div>
            <div class="point last">
              <div class="icon-culture">
                <FocalImage :src="icon_wins" />
              </div>
              <div class="point-text">
                <h1>Shared Wins</h1>
                <p>Success is what we owe each other. That's why every achievement, by every individual only propels us
                  to reach further</p>
              </div>
            </div>
          </div>
          <div class="image desktop_img">
            <FocalImage :src="building2_image" />
          </div>
        </div>
        <div class="offer" id="offer">
          <div class="section-heading spaced-heading">
            <h3>YOUR CAREER STARTS HERE</h3>
            <h2>Why Sunglass Hut is a great place to work</h2>
          </div>
          <div class="bullets">
            <div class="bullet">
              <div class="icon">
                <FocalImage :src="icon_opportunities" />
              </div>
              <div class="point-text">
                <h1>Gain unique experience in the luxury retail sector</h1>
              </div>
            </div>
            <div class="bullet">
              <div class="icon">
                <FocalImage :src="icon_customers" />
              </div>
              <div class="point-text">
                <h1>Get the opportunity to build impactful relationships with customers</h1>
              </div>
            </div>
            <div class="bullet">
              <div class="icon">
                <FocalImage :src="icon_team" />
              </div>
              <div class="point-text">
                <h1>Experience working in a close, collaborative team</h1>
              </div>
            </div>
            <div class="bullet">
              <div class="icon">
                <FocalImage :src="icon_rewards" />
              </div>
              <div class="point-text">
                <h1>Enjoy growth, training & career advancement opportunities</h1>
              </div>
            </div>
            <div class="bullet">
              <div class="icon">
                <FocalImage :src="icon_retail" />
              </div>
              <div class="point-text">
                <h1>Join a network of 180,000 employees in eyewear’s most famous brands through EssilorLuxottica</h1>
              </div>
            </div>
          </div>
        </div>
        <div class="section" id="role">
          <div class="text role">
            <TextComponent heading="Retail roles"
              text="We have opportunities fit for a wide range of backgrounds <br> including seasonal openings!"
              button="View all our in-store vacancies here"
              link="https://sgh.jobjack.co.za/LP" />
          </div>
          <div class="text dropdown">
            <ContentAccordion v-for="item in list" :key="item" :item="item" :src="item.activeBackground">
              <template v-slot:title />
              <template v-slot:content>
                <div class="m-3 text-sm text-left">
                  <p class="my-3 text-sm text-left" v-html="item.text"></p>
                  <b class="my-3 block">
                    {{ item.titleRequirements }}
                  </b>
                  <ul class="list-style list-outside">
                    <li v-for="(tag, index) in item.tags" :key="index" class="mb-3">
                      {{ tag }}
                    </li>
                  </ul>
                  <template v-if="item.titleQualification">
                    <b class="my-3 block">
                      {{ item.titleQualification }}
                    </b>
                    <ul class="list-style list-outside">
                      <li v-for="(tag, index) in item.tagsqua" :key="index" class="mb-3">
                        {{ tag }}
                      </li>
                    </ul>
                  </template>
                  <div v-if="item.Location">
                  <b class="my-3 block">Location</b>
                  <p class="mb-3 text-left ">
                    {{ item.Location }}
                  </p>
                  </div>
                  <div v-else>
                    <b class="my-3 block">Currently no vacancies</b>
                  </div>
                </div>
              </template>
            </ContentAccordion>
            <p class="role-text">Please email your CV and cover letter to <a
                href="mailto:careers@za.luxottica.com">careers@za.luxottica.com</a></p>
          </div>
        </div>
        <div class="section" id="know">
          <div class="section-heading">
            <h1 class="title">Don’t take our word for it. Check out what our employees are saying!</h1>
          </div>
          <Carousel :autoplay="10000" :wrap-around="true">
            <Slide v-for="slide in slides" :key="slide.id">
              <div class="carousel__item">
                <div class="carousel-image image">
                  <FocalImage :src="slide.image" />
                </div>
                <div class="text">
                  <p>{{ '"' }}{{ slide.statement }}{{ '"' }}</p>
                  <p>{{ "-" }}{{ slide.name }}</p>
                  <p>{{ slide.title }}</p>
                </div>
              </div>
            </Slide>
            <template #addons>
              <Navigation />
              <Pagination />
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import "./Careers.scss";
import 'vue3-carousel/dist/carousel.css';
import data from "../../appData/index";
import FocalImage from "../../components/atoms/FocalImage";
import TextComponent from "../../components/molecules/TextComponent";
import ContentAccordion from "../../components/molecules/ContentAccordion";
import { Carousel, Navigation, Slide, Pagination } from "vue3-carousel";
import { useMeta } from "vue-meta/dist/vue-meta.esm-browser";
export default {
  name: "Careers",
  components: { FocalImage, TextComponent, ContentAccordion, Carousel, Navigation, Slide, Pagination },

  data() {
    return {
      banner_image_desktop: "https://za.sunglasshut.com/shared_assets/site_images/careers/SGH-Web-Banner1200x400.jpg",
      banner_image_mobile: "https://za.sunglasshut.com/shared_assets/site_images/careers/SGH-Web-Banner-Mobile.jpg",
      building1_image: "https://za.sunglasshut.com/shared_assets/site_images/careers/sgh-building1.jpg",
      building2_image: "https://za.sunglasshut.com/shared_assets/site_images/careers/Desktop_section2.jpg",
      icon1: "https://ik.imagekit.io/yydzmnizi/image/catalog/brandstore/sunglasshutsa/custom/icon1.png",
      icon_drive: "https://za.sunglasshut.com/shared_assets/site_images/careers/icon-drive.png",
      icon_fun: "https://za.sunglasshut.com/shared_assets/site_images/careers/icon-fun.png",
      icon_inclusion: "https://za.sunglasshut.com/shared_assets/site_images/careers/icon-inclusion.png",
      icon_relationships: "https://za.sunglasshut.com/shared_assets/site_images/careers/icon-relationships.png",
      icon_wins: "https://za.sunglasshut.com/shared_assets/site_images/careers/icon-shared-wins.png",
      icon_retail: "https://za.sunglasshut.com/shared_assets/site_images/careers/retail.svg",
      icon_customers: "https://za.sunglasshut.com/shared_assets/site_images/careers/customers.svg",
      icon_team: "https://za.sunglasshut.com/shared_assets/site_images/careers/team.svg",
      icon_opportunities: "https://za.sunglasshut.com/shared_assets/site_images/careers/opportunities.svg",
      icon_brands: "https://cdn.zando.co.za/cms/sunglasshut/brands.png",
      icon_rewards: "https://za.sunglasshut.com/shared_assets/site_images/careers/rewards.svg",
      list: data.careers.content,
      slides: data.careers.slides,
    }
  },
  created() {
  },
  setup() {
    useMeta({
      title:
        "Sunglass Hut® South Africa Online Store | Careers",
      description:
        "Sunglass Hut® South Africa Online Store | Careers",
    });
  },
  methods: {
    moveUp(id) {
      document.getElementById(id).scrollIntoView({
        block: 'center',
      });
    },
  }
};
</script>
