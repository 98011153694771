export default {
  slides: [
    {
      id: 1,
      image: "",
      button: "Discover more",
      text: "Sunglasses for every moment",
      title: "",
      link: "/products/?collections=Find%2520Every%2520Shade%2520of%2520You%25202024",
      anchor: "promo1",
    },
    {
      id: 2,
      image: "",
      button: "Discover more",
      text: "Sunglasses for every moment",
      title: "",
      link: "/products/?collections=Find%2520Every%2520Shade%2520of%2520You%25202024",
      anchor: "promo2",
    },
  ]
};
