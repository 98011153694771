<template>
  <MenuItems
    v-for="propVal in list"
    :key="propVal"
    :child="propVal.children"
    class="menu-items"
  >
    <template v-slot:list-item>
      <a :href="propVal.url" :target="propVal.target"> {{ propVal.title }}</a>
    </template>
    <template v-slot:children-items>
      <ListItems :list="propVal" />
    </template>
  </MenuItems>
</template>

<script>
import "./Menu.scss";
import MenuItems from "../MenuItems";
import ListItems from "../ListItems";
export default {
  name: "Menu",
  components: { MenuItems, ListItems },
  props: ["list"],

  data: function() {
    return {
      propVal: this.list,
    };
  },

  mounted() {

     
      
      
      this.propVal[5].url = '/store-locator' ;
    }


};
</script>
