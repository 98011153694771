<template>
  <a :href="src_link" class="card">
    <div class="card-img-wrap">
       <!--<div v-if="item.saleprice" class="flag-tag">
      <span v-if="item.saleprice">-{{discountedPrice(item)}}%</span> 
      </div>-->
      <FocalImage @mouseleave="imageChange" v-show="hover" :src="`${imageBase}product_images/${item.ean}_030A.jpg`"  />
      <FocalImage @mouseenter="imageChange" v-show="active" :src="`${imageBase}product_images/${item.ean}_000A.jpg`" />
    </div>
    <div class="card-content">
      <div>
        <h3>{{ item.brand }}</h3>
        <p v-if="!isMobile()">{{ item.name }}</p>
        <p v-else>{{ item.name.substring(0,15)+".." }}</p>
      </div>
      <div>
        <p class="price-special  font-bold mb-0">R{{ item.saleprice ? item.saleprice: item.price}}.00 </p>
         <div class="sale-price ml-auto md:text-right" v-if="item.saleprice">R <span class="line-through">{{item.price}}.00</span></div>
      </div>
    </div>
  </a>
</template>

<script>
import "./Card.scss";

import FocalImage from "../../atoms/FocalImage/index";
export default {
  name: "Card",
  components: { FocalImage },
  props: ["item"],
  data() {
    return {
      hover: false,
      active: true,
      flag: false,
      src_link:'/sunglasshut-sa/'+this.item.brand+'/'+this.item.name.replace(/\s/g , "-").replace(/\//g, '-')+'-'+this.item.ean,
    };
  },
  methods: {
    imageChange() {
      this.hover = !this.hover;
      this.active = !this.active;
      this.flag = !this.flag;
    },
    discountedPrice(item) {
    return (100-(item.saleprice/item.price)*100)
  },
    isMobile() {
      if( screen.width <= 760 ) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
