<template>
  <div class="product-item text-center" v-for="(item, i) in Items" :key="i" @mouseover="showByIndex = i"
    @mouseout="showByIndex = null">
    <a :href="item.url">
      <div class="product-img">
        <img v-if="showByIndex === i" v-show="showByIndex === i"
          :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_030A.jpg`"
          :alt="`${item.name}`" />
        <img v-else :src="`https://za.sunglasshut.com/shared_assets/product_images/${item.imageOne}_000A.jpg`"
          :alt="`${item.name}`" />
      </div>
      <div class="product-info pb-0">
        <div class="product-name">{{ item.brand }}</div>
        <div v-if="item.band_sub" class="product-name-sub">{{ item.band_sub }}</div>
        <div v-if="item.band_sub2" class="product-name-sub2">{{ item.band_sub2 }}</div>
        <div v-if="item.text" class="product-name-sub2 mt-2">{{ item.text }}</div>
        <p v-if="item.sale_price" class="product-price red-text mb-0">{{ item.sale_price }}</p>
        <p v-if="item.price" :class="[item.sale_price ? 'product-price line-through' : 'product-price']">{{ item.price }}
        </p>
       <a :href="`${item.url}`" class="btn-shop button">Shop Now</a>
      </div>
    </a>
  </div>
</template>
<script>
import "./HomeProducts.scss";
export default {
  name: "HomeProducts",
  data() {
    return {
      showByIndex: null,
      hover: false,
      active: true,
      timer: null,
      current: 0,
      Items: [
        {
          imageOne: "889652323497",
          brand: "Gucci",
          price: "R7490.00",
          sale_price: "R3745.00",
          url: "/sunglasshut-sa/Gucci/GG0879S-889652323497",
          text: ""
        },
        {
          imageOne: "8056597419161",
          brand: "Burberry",
          price: "R3200.00",
          sale_price: "R2240.00",
          url: "/sunglasshut-sa/Burberry/BE4337-CARNABY-8056597419161",
          text: ""
        },
        {
          imageOne: "8053672737660",
          brand: "Ray-Ban",
          price: "R3660.00",
          sale_price: "R2928.00",
          url: "/sunglasshut-sa/Ray-Ban/RB3647N--8053672737660",
          text: ""
        },
        {
          imageOne: "8056597657952",
          brand: "Dolce&Gabbana",
          price: "R3880.00",
          sale_price: "R3104.00",
          url: "/sunglasshut-sa/Dolce&Gabbana/DG4403--8056597657952",
          text: ""
        },
        {
          imageOne: "888392473707",
          brand: "Oakley",
          price: "R3270.00",
          sale_price: "R2289.00",
          url: "/sunglasshut-sa/Oakley/OO9417-HOLBROOK-XL-888392473707",
          text: ""
        },
        {
          imageOne: "8056597900256",
          brand: "Prada",
          price: "R6520.00",
          sale_price: "R3260.00",
          url: "/sunglasshut-sa/Prada/PR-14ZS-8056597900256",
          text: ""
        }
      ],
    };
  },
};
</script>
