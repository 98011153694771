export default {
  content: [
    {
      "heading": "Marketing Intern",
      "text": "Primary point of contact between stores and Marketing. Communicate all VM and Tactical promotions to stores. Furthermore, assist in creation and distribution of all marketing material including props, inserts, decals and banners as well as general Marketing admin and support across the function.",
      "titleRequirements": "Requirements:",
      "tags": [
        "Luxottica Characteristics – passion, imagination, entrepreneurship, speed and simplicity.",
        "Extremely strong service focus and results orientation.",
        "Strong people orientation, relationship building and managing.",
        "Attention to detail.",
        "Ability to set and stick to deadlines.",
        "Shows Initiative and drive.",
        "Strong planning and organisational skills.",
        "Effective communication skills - written and verbal.",
        "Ability to get things done under pressure.",
        "Good time management skills.",
        "Strong admin skills.",
        "High level of accountability.",
        "Works well in a team with different types of people."
      ],
      "titleQualification": "Qualifications and Experience:",
      "tagsqua": [
        "Required – Marketing Diploma/Degree.",
        "Must have successfully operated in a quality business for at least one year.",
        "Retail industry experience is a strong advantage."
      ],
      "Location": "Head Office (CPT)"
    },
    {
      heading: "TEAM LEADER",
      text:
        "This position is responsible for the direct supervision of the store. The Team Leader functions to ensure achievement of sales and profit objectives, maintenance of service and operational standards and is responsible for the management, motivation and engagement of staff.  In addition, the Team Leader delivers superior sales results by helping customers select products best suited to their lifestyle and implements and executes initiatives as directed by the Centre Manager or company.",
        titleRequirements:"Requirements:",
        tags: [
          "Essential - Matric Certificate; previous experience in a retail or customer service environment and proven track record of achieving sales targets.",
          "Preferred - Tertiary retail management or commerce qualification with 2+ years’ retail management experience.",
        ],
        Location:"V&A Waterfront (CPT); Sandton (JHB)",
    },
    {
      heading: "STORE MANAGER",
      text:
        "This position is responsible for the direct supervision of the store. The Store Manager functions to ensure achievement of sales and profit objectives, maintenance of service and operational standards and is responsible for the management, motivation and engagement of staff.  In addition, the Store Manager delivers superior sales results by helping customers select products best suited to their lifestyle and implements and executes initiatives as directed by the Centre Manager or company.",
        titleRequirements:"Requirements:",
        tags: [
        "Essential - Matric Certificate; previous experience in a retail or customer service environment and a proven track record of achieving sales targets.",
        "Preferred - Tertiary retail management or commerce qualification with 2+ years’ retail management experience."],
        Location:"Cape Town International Airport (CPT); Ballito Junction (DUR); Ilanga Mall (NLP); Clearwater Mall (JHB);",
    },
    {
      heading: "CENTRE MANAGER",
      text:
        "This position is responsible for the direct supervision of two (2) or more stores within a Centre.  The Centre Manager functions to ensure achievement of sales and profit objectives, maintenance of service and operational standards and is responsible for the management, motivation and engagement of staff.  In addition, the Centre Manager delivers superior sales results by helping customers select products best suited to their lifestyle and implements and executes initiatives as directed by the Regional Manager or company.",
        titleRequirements:"Requirements:",
        tags: [
        "Essential - Matric Certificate; previous experience in a retail or customer service environment and a proven track record of achieving sales targets.",
        "Preferred - Tertiary retail management or commerce qualification with 2+ years’ retail management experience."],
        Location:"Western Cape; Mall of Africa (JHB)",

    },
  ], slides: [
    {
      "id": 1,
      "image": "https://za.sunglasshut.com/shared_assets/site_images/careers/uvashna.jpg",
      "statement" : "My journey at Sunglass Hut is truly a phenomenal one and after 9 years of being apart of the family, I’m still so in-love with the brand. The learnings you gain, relationships and connections you get to build with our people from all departments and customers leads you to being the best vision of yourself. I personally am so thankful to be apart of this team and brand as it has grown me tremendously not only in my career at Sunglass Hut but as the woman I am today. Sunglass Hut has given me my independence and more importantly the confidence to take on any challenge. The culture we share at Sunglass Hut is truly unique.",
      "name": "Uvashna",
      "title": "Centre Manager",
    }, {
      "id": 2,
      "image": "https://za.sunglasshut.com/shared_assets/site_images/careers/bea01.jpg",
      "statement" : "I always wanted to work for an International Company where I can take my success to the next level. I am one of the youngest employees in Sunglass Hut and joined the company fresh out of High School and yet I feel respected and taken care of here. The Culture is different and people's value are felt. I used to believe that work is never associated with fun. Something that Sunglass Hut people taught me is to have fun while you work and to enjoy it. I am a very principal orientated type of person - Sunglass Hut taught me how to embrace other's better and how to mature in my, leadership.",
      "name": "Bea",
      "title": "Centre Manager",
    }, {
      "id": 3,
      "image": "https://za.sunglasshut.com/shared_assets/site_images/careers/xolisa.jpg",
      "statement": "What I love the most about Sunglass Hut, is that it gives me the ability to be myself and how it allows inclusion and participation. We are a big family connected by shared wins, no matter where you go you always find energy and fun at Sunglass Hut.",
      "name":  "Xolisa",
      "title": "Centre Manger",
    }
  ]
};
